import React, {useContext, useEffect} from 'react';
import styled from 'styled-components';
import {Button, Input, message, Spin} from 'antd';
import {navigate} from 'gatsby';
import {Context} from '../../AppContext';

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

function StaffLandingPage(props) {
  const [values, setValues] = React.useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = React.useState(false);
  const app = useContext(Context);
  const {user, autoLogining} = app.state;

  const getValue = (key) => values[key];
  const onValueChange = (key) => (e) =>
    setValues({...values, [key]: e.target.value});

  async function onLogin() {
    setLoading(true);
    try {
      await app.actions.login(values);
      navigate('/customers');
      message.success('登入成功');
    } catch (err) {
      console.warn(err);
      message.error('登入失敗');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!autoLogining && user) {
      navigate('/customers');
    }
  }, [autoLogining, user]);

  return (
    <Wrapper>
      <div className="box">
        <header>
          <img alt="logo" src="/images/logo.png" />
          <h2>內部報價系統</h2>
        </header>

        {app.state.autoLogining ? (
          <Spin style={{display: 'block', margin: '0 auto'}} />
        ) : (
          <form>
            <label>帳號</label>
            <Input
              id="sens-quotation-username"
              style={{marginBottom: 20}}
              value={getValue('username')}
              onChange={onValueChange('username')}
            />

            <label>密碼</label>
            <Input
              id="sens-quotation-password"
              style={{marginBottom: 30}}
              type="password"
              value={getValue('password')}
              onChange={onValueChange('password')}
            />

            <Button loading={loading} type="primary" onClick={() => onLogin()}>
              登入
            </Button>
          </form>
        )}
      </div>

      {/* <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
          <Checkbox>記住我</Checkbox>

          <div style={{flex: 1}} />

          <Button type="text" style={{color: '#ccc'}}>
            忘記密碼
          </Button>
        </div> */}

      <div style={{height: 50}}>
        Powered By&nbsp;
        <a
          style={{display: 'inline', fontSize: 16, color: '#1BA7BE'}}
          href="https://eculturetech.pse.is/link-from-hiwork"
          target="_blank"
          rel="noreferrer">
          奕果雲端數位 EcultureTech
        </a>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > .box {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    & > header {
      padding: 0 20px;
      margin-bottom: 20px;
      display: flex;
      align-self: center;
      align-items: center;

      & > h2 {
        padding: 0;
        margin: 0;
      }

      & > img {
        height: auto;
        width: 67px;
        margin-right: 20px;
      }
    }

    & > form {
      display: flex;
      flex-direction: column;
      opacity: 1;
      transition: all 0.2s linear;

      & > label {
        margin: 0 10px;
      }

      & > input {
        margin-bottom: 20px;
      }

      & > button {
        width: 100%;
      }
    }
  }
`;

export default StaffLandingPage;
